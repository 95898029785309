const STRINGS = {
  sectionTitle: 'Email Manager',
  subtitle: 'View email accounts, disk usage, and manage your email settings',
  emptyManager:
    'You currently have no Cloud Email addresses. Get started for free above!',
  newPackageButton: 'Create Email Package',
  options: 'Options',
  addressHeader: 'Email Address',
  diskSpaceHeader: 'Disk Space',
  actionRequired: 'Action Required',
  managePassword: 'Manage Password',
  emailSettings: 'Settings',
  renewal: 'Renews on',
  expiry: 'Expires on',
  reactivatePackage: 'Activate Package',
  deletePackage: 'Cancel Package',
  reactivateEmail: 'Activate Email',
  deleteEmail: 'Delete Email',
  subscriptionStatus: 'Subscription',
  addUserButton: 'Add Email',
  editButton: 'Edit',
  settings: 'Settings',
  upgrade: 'Upgrade',
  newPasswordRequired: 'New Password Required',
  changePassword: 'Change Password',
  passwordSuccess: 'Password successfully changed!',
  accountIsMigratingWarning:
    "The upgrade is currently underway, please wait as we're now updating your account to reflect the changes. This can take a few minutes.",
  accountIsCompletingMigrationWarning:
    "The upgrade is currently completing, please wait as we're now updating your account to reflect the changes. This can take a few minutes",
  suspendedError:
    '<b>Your package has been suspended due to failed billing.</b> To reactivate your email please process the outstanding balance in your shopping cart or contact Customer Support for help.',
  fetchError:
    'Error retrieving email accounts. Please try refreshing your page, if the issue persists please contact support',
  fetchErrorDueToMigration:
    'Our apologizes, processing the upgrade is taking longer than expected. Please try again later and if the issue persists please contact support.',
  passwordError: 'Error updating email password',
  createEmail: (actionRequired = false) =>
    !actionRequired ? 'Add' : 'Create Email',
  provisionCreateEmail: 'Create Your Email Address',
  addEmailText: price => `Add an email - ${price}/mo`,
  createEmailSuccess: 'Email successfully created!',
  createEmailError: 'Error creating email account',
  createEmailErrorDuplicate: 'Already exists as email or alias',
  aliases: 'Aliases',
  forwarding: 'Forwarding',
  addCell: 'Add',
  count: (type, used, total) => `${type}: ${used} of ${total}`,
  aliasDeleteError: 'Error deleting alias',
  aliasAddError: 'Error adding alias',
  aliasFetchError: 'Error fetching aliases',
  aliasDupError: 'Already exists as email or alias',
  forwardingStatusError: 'Error changing forwarding status',
  forwardingDeleteError: 'Error deleting forwarding address',
  forwardingAddError:
    'Error adding forwarding address, please contact customer support',
  forwardingFetchError: 'Error fetching forwarding addresses',
  forwardingInfoBox:
    'Forwarded emails will be automatically saved on the server. Each email may only have a maximum of 4 forwarded emails.',
  forwardingAddLimitWarning:
    'There are more than 4 forwarding addresses on this email. Please remove any address until there are 4 or less remaining.',
  activateError: 'Error restoring package',
  activateSuccess: 'Successfully restored package',
  changePackage: 'Change Package',
  changePackageDisclaimer:
    'Your new price will be reflected on your next billing date',
  changePackageError: 'Unable to change package',
  changePackageLoading: 'Changing your package...',
  deleteAccount: {
    header: (domain = '') => `Cancel Package ${domain}`,
    subheader:
      'Your package will be cancelled at the end of the current billing period',
    question: 'Do you really want to cancel the package?',
    warning:
      'After your billing period ends all email addresses and data associated with this package will be irrevocably deleted.',
    success: 'Successfully set package to pending cancellation',
    error: 'Error deleting package',
  },
  deleteUser: {
    header: (domain = '') => `Delete Email ${domain}`,
    subheader: 'Your email address will be deleted immediately.',
    question: 'Do you really want to delete the email address?',
    warning:
      'This email address and all mail and data will be permanently and irrevocably deleted.',
    success: 'Successfully deleted email address!',
    error: 'Error deleting email',
    badRequestError: 'Removal failed: Email likely already deleted',
  },
  restoreAccount: {
    header: 'Activate Package?',
    success: 'Successfully activated package!',
    error: 'Error reactivating package',
  },
  restoreUser: {
    header: 'Restore Email',
    question: 'Restore email',
    warning: '',
    success: 'Successfully restored email!',
    error: 'Error restoring email',
  },
  packages: {
    CLOUD_BASIC: 'Cloud Basic',
    CLOUD_PRO: 'Cloud Productivity',
    CLOUD_SEC: 'Cloud Security+',
    CLOUD_SUPER: 'Cloud Super',
    CLOUD_BULK: 'Cloud Bulk',
  },
  cancel: 'Cancel',
  save: 'Save',
  saving: 'Saving...',
  back: 'Back',
  confirm: 'Confirm',
  yes: 'Yes',
  no: 'No',
  loadingEmailCTA: 'Creating your email...',
  webMail: 'Webmail',
  emailSetup: 'Email Setup',
  activeUsers: (itemCode, numberOfUsers) => {
    if (numberOfUsers === 0) return ''
    if (itemCode) {
      return `${STRINGS.packages[itemCode]} (${numberOfUsers} user${
        numberOfUsers > 1 ? 's' : ''
      })`
    }
    return ''
  },
}

export const PRICING_ITEM_CODES = {
  CLOUD_BASIC: 'CLOUD_BASIC_USER',
  CLOUD_PRO: 'CLOUD_PRO_USER',
  CLOUD_SEC: 'CLOUD_SEC_USER',
  CLOUD_SUPER: 'CLOUD_SUPER_USER',
  CLOUD_BULK: 'CLOUD_BULK_USER',
}

export const PACKAGE_ITEM_CODES = {
  1: 'CLOUD_BASIC',
  2: 'CLOUD_PRO',
  7: 'CLOUD_SEC',
  10: 'CLOUD_SUPER',
  13: 'CLOUD_BULK',
}

export const ANALYTICS_EVENTS = {
  cloudEmail: {
    event: 'Cloud Email',
    types: {
      buyPage: 'Buy Page',
      configurator: 'Configurator',
      purplePanel: 'Email Manager - Purple Panel',
      addEmail: 'Email Manager - Existing Package',
    },
  },
}

export const USERNAME_REGEX = /^\w+([\.-]?\w+)*$/

export default STRINGS
